
<!-- <app-header id="header"></app-header> -->

<router-outlet></router-outlet>

<div class="loadingContainer" *ngIf="showLoading">
    <mat-spinner></mat-spinner>
</div>

<div class="notification-container"></div>
<!-- <app-footer id="footer"></app-footer> -->