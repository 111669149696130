import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Page404Component } from './overview/page404/page404.component';
import { MandantSelectionComponent } from './overview/mandant-selection/mandant-selection.component';
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: MandantSelectionComponent
  },
  {
    path: 'home',
    pathMatch: 'full',
    component: MandantSelectionComponent
  },
  {
    path: 'tarif',
    pathMatch: 'full',
    component: MandantSelectionComponent
  },
  {
    path: 'landingpage/:mandantID',
    loadChildren: () => import('./mandants/universal/universal.module').then(m => m.UniversalPageModule),
  },
  {
    path: 'landingpageStatic/digi',
    loadChildren: () => import('./mandants/digi/digi.module').then(m => m.DigiPageModule),
  },
  {
    path: 'landingpageStatic/cocloud',
    loadChildren: () => import('./mandants/cocloud_showcase/coCloud.module').then(m => m.CoCloudPageModule),
  },
  {
    path: 'landingpageStatic/bse',
    loadChildren: () => import('./mandants/bse/bse.module').then(m => m.BSEModule),
  },


  // {
  //   path: 'landingpageStatic/wevee',
  //   loadChildren: () => import('./mandants/WeVee/wevee.module').then(m => m.WeveeModule)
  // },
  // {
  //   path: 'landingpageStatic/scharr',
  //   loadChildren: () => import('./mandants/scharr_waerme/scharr.module').then(m => m.ScharrModule)
  // },

  // {
  //   path: 'landingpageStatic/muench',
  //   loadChildren: () => import('./mandants/muench_energie/muench.module').then(m => m.MuenchModule)
  // },
  // {
  //   path: 'landingpageStatic/yourstrom',
  //   loadChildren: () => import('./mandants/yourstrom_new/yourstromnew.module').then(m => m.YourStromNewModule)
  // },  

  {
    path: 'landingpageStatic/westfalenwind',
    loadChildren: () => import('./mandants/oekorenta/oekorenta.module').then(m => m.OekorentaModule)
  },
  // {
  //   path: 'landingpageStatic/proefa',
  //   loadChildren: () => import('./mandants/proefa/proefa.module').then(m => m.ProefaModule)
  // },
  { path: '404', component: Page404Component },
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
   //  enableTracing: true, 
    scrollPositionRestoration: 'enabled',

  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
