<div class="fixedHeader" id="fixedHeader"></div>
<div class="row">
    <div class="col-lg-12">
        <div class="wrapper404">
            <div style="text-align: center;">
                <h1 >Fehler 404</h1>
                <h5>Die Angegebene Seite konnte nicht gefunden werden</h5>
                <button ibmButton="primary" [routerLink]="['/']">Zurück zum Start</button>
            </div>
        </div>
    </div>
</div>

