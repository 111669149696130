import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { mcpConfig } from '../models/mcpConfig';

@Injectable({
  providedIn: 'root'
})
export class McpService {
  configuration$: ReplaySubject<any> = new ReplaySubject(null);

  constructor(
    private http: HttpClient,
    private router:Router) {
    this.loadConfiguration().subscribe((val: mcpConfig) => {
      // this.setColors(val.mandant_primary, val.mandant_primary_light, val.mandant_header,val.mandant_header_text_color,val.btn_text_color);
      // this.setFont(val.font)
    });
  }


  getClients() {
    return this.http.get(`https://mcp.swaas.de/clients.json`)
  }

  getClientProperties() {
    return this.http.get(`https://mcp.swaas.de/client/135/property`)
  }

  getClientPropertiesWithID(id:number) {
    return this.http.get(`https://mcp.swaas.de/client/${id}/property`)
  }


  loadConfiguration(): Observable<any> {
    return this.http.get(`https://mcp.swaas.de/client/135/property`).pipe(
      map(config => this.keyValueArrayToObject(config)),
      map((config: any) => {
        this.configuration$.next(config);
        return config;
      })
    )
  }

  loadConfigurationWithID(id:number) : Observable<any> {
    return this.http.get(`https://mcp.swaas.de/client/${id}/property`).pipe(
      map(config => this.keyValueArrayToObject(config)),
      map((config: any) => {
        this.configuration$.next(config);
        return config;
      })
    )
  }

  keyValueArrayToObject(config): {} {
    let newObject = {}
    if (Array.isArray(config)) {
      // convert MCP structure to object
      config.forEach(c => {
        if (c.key === 'mandantid') {
          newObject[c.key] = +c.value;
        } else if (c.key === 'faq' || c.key === 'support_hours' || c.key == 'bank_data' || c.key == 'bank_data_2' || c.key == 'newsletter' || c.key == 'external_websites_items' || c.key == 'theme_beta' || c.key == 'dashboardAds' || c.key == 'appWideNotification') {
          newObject[c.key] = JSON.parse(c.value);
        } else if (c.value.toLowerCase() == 'true' || c.value.toLowerCase() == 'false') {
          newObject[c.key] = JSON.parse(c.value)
        } else {
          newObject[c.key] = c.value
        }
      });
    } else {
      config = config;
    }
    return newObject
  }


  getConfiguration() {
    return this.configuration$.asObservable();
  }


  setColors(primaryColor, primaryColorLight, headerColorBG,heaerText,btnTextColor) {
    document.body.style.setProperty('--mandant-primary', primaryColor);
    document.body.style.setProperty('--mandant-primary-light', primaryColorLight);
    document.body.style.setProperty('--mandant-header-color', headerColorBG);
    document.body.style.setProperty('--mandant_header_text_color', heaerText);
    document.body.style.setProperty('--mandant_btn_text_color', btnTextColor);
  }

  setFont(font) {
    document.body.style.setProperty('font-family', font);
  }
}
