// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*

  Productionwmandaant
  powerCloudRestURL: 'https://powercloud-proxy.herokuapp.com/https://gates-prod.powercloud.de/rest:client',
  powerCloudRestKey: '4cf88a837fadfb23b58aeea8ce0c68fc',
  Authorization: 'Basic Y2xpZW50I0NvQ2xvdWQ6QWJHaFpyUDYzN0Z0KCLCpys=',
  powerCloudSOAPAuthName: 'CoCloud GmbH',
  powerCloudSOAPAuthPassword : 'b614452dbf0982fe420cbbbcf1fdb66154f4791a',
  Campaingn : 'JS2021'
*/
  //Testing config => Testmandaant
  /*
    powerCloudRestURL: 'https://powercloud-proxy.herokuapp.com/https://gates-test.powercloud.de/rest:client',
    powerCloudRestKey: 'd327c3778049c9fbc597e3cbe885e3db',
    Authorization: 'Basic Y2xpZW50I2NvY2xvdWRnbWJoOkVWYUVjOGh5RlZ1UjI2OQ',
    powerCloudSOAPAuthName: 'test',
    powerCloudSOAPAuthPassword: 'cf8d1964fba63909f81d158944c1c894079d56bc',
    Campaingn: 'JS2021'
    */
  //Testing config => ShowStartUp Mandant
  powerCloudRestURL: 'https://gates-test.powercloud.de/rest:client',
  powerCloudRestKey: '48a94b71b0db514ee6e01d16f8e5418d',
  Authorization: '',
  powerCloudSOAPAuthName: '_20210628143231_ShowCaseStartUp',
  powerCloudSOAPAuthPassword: '51594b823e227631e1f5b9fa4ee8e92cfa26ce7d',
  Campaingn: 'MESTANDARD1',
  CampaingnWater: 'SHOWCASEWASSER',
  CampaingnGas: 'SHOWCASEGAS',
  ProxyURl : 'https://proxy-dev.swaas.de/135/enet',
  ProxyURlRest : 'https://proxy-dev.swaas.de/135/pcclient',
  ProxyClean : 'https://proxy-dev.swaas.de/135',
  portalURL: "",
  Campaingn_Gas: 'CCEPEX',
  priceAPIUrl: 'http://127.0.0.1:8080/https://proxy-dev.swaas.de/100/apienergycharts/price'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
