import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mandant-selection',
  templateUrl: './mandant-selection.component.html',
  styleUrls: ['./mandant-selection.component.scss']
})
export class MandantSelectionComponent implements OnInit {

  selectedMandant;
  mandants = [
    // {
    //   content: "WeVee", value: "landingpageStatic/wevee"
    // },
    // {
    //   content: "Uelzen", value: "landingpageStatic/uelzen"
    // },
    // {
    //   content: "YourStrom", value: "landingpageStatic/yourstrom/start"
    // },
    // {
    //   content: "Münchenergie", value: "landingpageStatic/muench/start"
    // },
    // {
    //   content: "e-Mobilio", value: "landingpageStatic/e-mobilio"
    // },
    {
      content: "westfalenwind", value: "landingpageStatic/westfalenwind/start"
    },
    // {
    //   content: "Proefa", value: "landingpageStatic/proefa/start"
    // }


    {
      content: "BSE Strom und Erdgas", value: "landingpageStatic/bse/start"
    },

    {
      content: "Digi Energy", value: "landingpageStatic/digi/start"
    },
    // {
    //   content: "CoCloud Showcase", value: "landingpageStatic/cocloud/start"
    // },

  ];
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  moveToStartPage() {
    this.router.navigate([`${this.selectedMandant.value}`])
  }
}
