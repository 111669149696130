import { Component, OnInit } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { McpService } from './services/mcp.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'JobStrom';
  showLoading:boolean = false;
  
  constructor(
    private mcp:McpService,
    private loadingService:LoadingService){
      this.loadingService.loading$.subscribe(loading => {
        this.showLoading = loading;
      })
  }

  ngOnInit(){
 
  }


}
