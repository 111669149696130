<div class="row">
    <div class="col-lg-12">
        <div class="mandantWrapper">
            <div class="card">
                <label style="font-size: 25px;">Mandant auswählen</label>

                <!-- <select [(ngModel)]="selectedMandant" class="select">
                  <option *ngFor="let item of mandants" value="{{item.value}}">{{item.showValue}}</option>
                </select> -->
                <ibm-dropdown [(ngModel)]="selectedMandant">
                    <ibm-dropdown-list [items]="mandants"></ibm-dropdown-list>
                </ibm-dropdown>

                <button class="primaryBtn" (click)="moveToStartPage()"> Weiter</button>
            </div>

        </div>
    </div>
</div>