import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading$: Subject<boolean> = new ReplaySubject(1)
  constructor() {
    this.loading$.next(false);
   }


  public show() {
    this.loading$.next(true);
  }


  public hide() {
    this.loading$.next(false);
  }
}
