import { NgModule, CUSTOM_ELEMENTS_SCHEMA, TRANSLATIONS, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MandantSelectionComponent } from './overview/mandant-selection/mandant-selection.component';
import { Page404Component } from './overview/page404/page404.component';
import { SwiperModule } from 'swiper/angular';
import { AppCarbonModule } from './helpers/carbon-helper.module';

import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { AppMaterialModule } from './helpers/app-material.module';
export function playerFactory() {
  return player;
}


@NgModule({
  declarations: [
    AppComponent,
    MandantSelectionComponent,
    Page404Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    SwiperModule,
    AppCarbonModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers :[
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule {
}
