import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, StructuredListModule, TagModule } from 'carbon-components-angular'
import { SkeletonModule } from 'carbon-components-angular'
import { GridModule } from 'carbon-components-angular'
import { LoadingModule, InlineLoadingModule } from 'carbon-components-angular'
import { InputModule } from 'carbon-components-angular'
import { ModalModule } from 'carbon-components-angular'
import { NumberModule, TilesModule } from 'carbon-components-angular'
import { ProgressIndicatorModule, DropdownModule } from 'carbon-components-angular'
import { DatePickerModule, SliderModule, SelectModule, CheckboxModule, AccordionModule, PlaceholderModule } from 'carbon-components-angular'
import { SideNavModule } from 'carbon-components-angular'
import { NotificationModule } from 'carbon-components-angular'
import { BreadcrumbModule } from 'carbon-components-angular'
import { TableModule } from 'carbon-components-angular'
import { HeaderModule } from 'carbon-components-angular'
import { ContextMenuModule, ContentSwitcherModule, DialogModule, PanelModule } from 'carbon-components-angular'
import { RadioModule } from 'carbon-components-angular'
import { ListModule } from 'carbon-components-angular'
import { ComboBoxModule, } from 'carbon-components-angular'
import { PaginationModule } from 'carbon-components-angular'

import { IconModule } from 'carbon-components-angular';


@NgModule({
    imports: [
        CommonModule,
        ButtonModule,
        SkeletonModule,
        GridModule,
        LoadingModule,
        InlineLoadingModule,
        InputModule,
        ModalModule,
        NumberModule,
        ProgressIndicatorModule,
        DropdownModule,
        TilesModule,
        IconModule,
        DatePickerModule,
        SliderModule,
        SelectModule,
        CheckboxModule,
        AccordionModule,
        PlaceholderModule,
        StructuredListModule,
        SideNavModule,
        NotificationModule,
        BreadcrumbModule,
        TableModule,
        HeaderModule,
        ContextMenuModule,
        ContentSwitcherModule,
        DialogModule,
        PanelModule,
        RadioModule,
        ListModule,
        ComboBoxModule,
        PaginationModule,
        TagModule,
    ],
    exports: [
        CommonModule,
        ButtonModule,
        SkeletonModule,
        GridModule,
        LoadingModule,
        InlineLoadingModule,
        InputModule,
        ModalModule,
        NumberModule,
        ProgressIndicatorModule,
        DropdownModule,
        TilesModule,
        IconModule,
        DatePickerModule,
        SliderModule,
        SelectModule,
        CheckboxModule,
        AccordionModule,
        PlaceholderModule,
        StructuredListModule,
        SideNavModule,
        NotificationModule,
        BreadcrumbModule,
        TableModule,
        HeaderModule,
        ContextMenuModule,
        ContentSwitcherModule,
        DialogModule,
        PanelModule,
        RadioModule,
        ListModule,
        ComboBoxModule,
        PaginationModule,
        TagModule,
    ]
})
export class AppCarbonModule {

}